import { playbackRates } from "../helpers/playbackRates";
import { brandConfig } from "../helpers/brandConfig";
import { getEnvVarOrRaise } from "../helpers/envVars";
import {
  ResourceConsumerWithResource,
  VideoMetadata,
} from "../pages/learningLibrary/learningLibraryTypes";

const environment = getEnvVarOrRaise("ENV");

function getR2Path(resourceConsumer: ResourceConsumerWithResource) {
  return [
    brandConfig.brandAbbreviation,
    "resources",
    resourceConsumer.resource_id,
  ].join("/");
}

function getUrlHost() {
  return {
    local: "https://stg.hobbyresources.com",
    stg: "https://stg.hobbyresources.com",
    prd: "https://www.hobbyresources.com",
  }[environment || "local"];
}

export const getPosterImageForVideoJS = (
  resourceConsumer: ResourceConsumerWithResource
) => {
  const path = getR2Path(resourceConsumer);
  const urlHost = getUrlHost();
  const url = `${urlHost}/${path}/output/poster/poster.jpg`;
  return url;
};

const generateVideoJsOptions = (
  resourceConsumer: any,
  thumbnail = false,
  autoplay = false
) => {
  let path = getR2Path(resourceConsumer);

  const resolutions =
    (resourceConsumer.metadata as unknown as VideoMetadata)
      ?.available_resolutions || [];
  const duration =
    (resourceConsumer.metadata as unknown as VideoMetadata)?.duration || -1;

  const urlHost = getUrlHost();

  const sources = resolutions.map((resolution) => {
    const number = resolution.split("p")[0];
    return {
      src: `${urlHost}/${path}/output/${number}/video.mp4`,
      type: "video/mp4",
      label: resolution,
    };
  });

  const tracks =
    duration === -1 || duration >= 7200
      ? []
      : [
          {
            src: `${urlHost}/${path}/output/transcriptions/transcribe.vtt`,
            kind: "captions",
            label: "English",
            srclang: "en",
          },
        ];

  if (thumbnail) {
    return {
      sources,
      tracks,
      fluid: true,
      loadMuted: true,
      loadingSpinner: false,
      poster: getPosterImageForVideoJS(resourceConsumer),
    };
  }

  const options = {
    autoplay,
    watermark: true,
    controls: true,
    fluid: true,
    playbackRates,
    sources,
    tracks,
    poster: getPosterImageForVideoJS(resourceConsumer),
    plugins: {
      vttThumbnails: {
        src: `${urlHost}/${path}/output/thumbnails/thumbnails.vtt`,
      },
    },
  };

  return options;
};

export default generateVideoJsOptions;
