import React, { useState, useRef, useEffect } from "react";
import { MdRepeat, MdPlayArrow, MdPause } from "react-icons/md";
import { playbackRates } from "../helpers/playbackRates";

const Audio = ({
  className,
  source,
  type,
}: {
  className?: string;
  source: string;
  type?: string;
}) => {
  const [isLooping, setIsLooping] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackSpeed;
    }
  }, [playbackSpeed]);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    const handleDurationChange = () => setDuration(audio.duration);
    const handleEnded = () => setIsPlaying(false);

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("durationchange", handleDurationChange);
    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("durationchange", handleDurationChange);
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const handleSpeedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPlaybackSpeed(parseFloat(event.target.value));
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch((error) => {
          console.error("Playback failed:", error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleProgressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseFloat(event.target.value);
    if (audioRef.current) {
      audioRef.current.currentTime = time;
      setCurrentTime(time);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const fileType = () => {
    const fileExtension = source.split(".").pop();
    if (fileExtension === "m4a") {
      return "audio/x-m4a";
    } else if (fileExtension === "mp3") {
      return "audio/mp3";
    }
    return "audio/mp3";
  };

  return (
    <div
      className={`bg-white rounded-lg shadow p-3 w-[600px] max-w-full ${className}`}
    >
      <audio ref={audioRef} loop={isLooping} className="hidden">
        <source src={source} type={type || fileType()} />
        Your browser does not support the audio tag.
      </audio>

      <div className="flex items-center gap-2">
        <button
          onClick={handlePlayPause}
          className="rounded-full h-8 w-8 flex items-center justify-center bg-brand-primary text-white hover:bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-brand-primary"
          aria-label={isPlaying ? "Pause" : "Play"}
        >
          {isPlaying ? <MdPause size={20} /> : <MdPlayArrow size={20} />}
        </button>

        <div className="flex-grow flex items-center gap-2 min-w-0">
          <span className="text-xs text-gray-500 w-8">
            {formatTime(currentTime)}
          </span>
          <input
            type="range"
            min="0"
            max={duration || 0}
            value={currentTime}
            onChange={handleProgressChange}
            className="flex-grow h-1.5 rounded-lg appearance-none bg-gray-200 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-2.5 [&::-webkit-slider-thumb]:h-2.5 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-brand-secondary"
            aria-label="Audio progress"
          />
          <span className="text-xs text-gray-500 w-8">
            {formatTime(duration)}
          </span>
        </div>

        <select
          value={playbackSpeed}
          onChange={handleSpeedChange}
          className="w-16 px-1.5 py-0.5 text-xs rounded border border-gray-300 bg-white text-gray-700 cursor-pointer hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-brand-primary"
          aria-label="Playback Speed"
        >
          {playbackRates.map((speed) => (
            <option key={speed} value={speed}>
              {speed}x
            </option>
          ))}
        </select>

        <button
          onClick={() => setIsLooping(!isLooping)}
          className={`rounded-full h-8 w-8 flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-brand-primary ${
            isLooping ? "text-brand-primary" : "text-gray-400"
          }`}
          aria-label="Repeat"
        >
          <MdRepeat size={20} />
        </button>
      </div>
    </div>
  );
};

export default Audio;
